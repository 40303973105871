import React from 'react'

export const GradientThingy = () => {
	const colors = {
    mintyBoy: '#00f090',
    ultramarinePurp: '#5135ff',
    cadmiumCrimson: '#FF5828',
    paleFuscia: '#F69CFF',
    cadmiumGold: '#FFA50F',
  }

	return (
<div>
	<div id="gradient-bg" class="position-absolute w-100 h-100 bg-cool-gray-1 trbl-0" style={{zIndex: '-1', pointerEvents: 'none'}}></div>
   	<div id="gradient" class="position-absolute w-100 h-100 d-flex justify-content-center align-items-center overflow-hidden trbl-0 observablehq" style={{zIndex: '-1', pointerEvents: 'none'}}>
     	<svg viewBox="0 0 3000 3000" preserveAspectRatio="xMidYMid slice" class="flex-shrink-0" style={{minWidth: '100%', minHeight: '100%', filter: 'saturate(150%)', '-webkit-filter': 'saturate(150%)'}}>
			 <defs>
         <radialGradient id="rg0" fx="0.34102934270211693" fy="0.5">
           <stop offset="0%" stopColor={colors.ultramarinePurp}></stop>
           <stop offset="100%" stopColor={colors.ultramarinePurp} stop-opacity="0"></stop>
         </radialGradient><radialGradient id="rg0" fx="0.3006439454812611" fy="0.5">
           <stop offset="0%" stopColor={colors.ultramarinePurp}></stop>
           <stop offset="100%" stopColor={colors.ultramarinePurp} stop-opacity="0"></stop>
         </radialGradient><radialGradient id="rg0" fx="0.3012934258152014" fy="0.5">
           <stop offset="0%" stopColor={colors.ultramarinePurp}></stop>
           <stop offset="100%" stopColor={colors.ultramarinPurp} stop-opacity="0"></stop>
         </radialGradient><radialGradient id="rg1" fx="0.33950130231501097" fy="0.5">
           <stop offset="0%" stopColor={colors.cadmiumCrimson}></stop>
           <stop offset="100%" stopColor={colors.cadmiumCrimson} stop-opacity="0"></stop>
         </radialGradient><radialGradient id="rg1" fx="0.37372348135893574" fy="0.5">
           <stop offset="0%" stopColor={colors.cadmiumCrimson}></stop>
           <stop offset="100%" stopColor={colors.cadmiumCrimson} stop-opacity="0"></stop>
         </radialGradient><radialGradient id="rg1" fx="0.3738815592848491" fy="0.5">
           <stop offset="0%" stopColor={colors.cadmiumCrimson}></stop>
           <stop offset="100%" stopColor={colors.cadmiumCrimson} stop-opacity="0"></stop>
         </radialGradient><radialGradient id="rg2" fx="0.3622857763861167" fy="0.5">
           <stop offset="0%" stopColor={colors.paleFuscia}></stop>
           <stop offset="100%" stopColor={colors.paleFuscia} stop-opacity="0"></stop>
         </radialGradient><radialGradient id="rg2" fx="0.34466270581008895" fy="0.5">
           <stop offset="0%" stopColor={colors.paleFuscia}></stop>
           <stop offset="100%" stopColor={colors.paleFuscia} stop-opacity="0"></stop>
         </radialGradient><radialGradient id="rg2" fx="0.3028211106599178" fy="0.5">
           <stop offset="0%" stopColor={colors.paleFuscia}></stop>
           <stop offset="100%" stopColor={colors.paleFuscia} stop-opacity="0"></stop>
         </radialGradient><radialGradient id="rg3" fx="0.3955502809005557" fy="0.5">
           <stop offset="0%" stopColor={colors.mintyBoy}></stop>
           <stop offset="100%" stopColor={colors.mintyBoy} stop-opacity="0"></stop>
         </radialGradient><radialGradient id="rg3" fx="0.3328717071340566" fy="0.5">
           <stop offset="0%" stopColor={colors.mintyBoy}></stop>
           <stop offset="100%" stopColor={colors.mintyBoy} stop-opacity="0"></stop>
         </radialGradient><radialGradient id="rg3" fx="0.37692409028853774" fy="0.5">
           <stop offset="0%" stopColor={colors.mintyBoy}></stop>
           <stop offset="100%" stopColor={colors.mintyBoy} stop-opacity="0"></stop>
         </radialGradient>
       </defs>  	
				
				<defs>
      	  <radialGradient id="rg0" fx="0.34102934270211693" fy="0.5">
      	    <stop offset="0%" stop-color="#5135FF"></stop>
      	    <stop offset="100%" stop-color="#5135FF" stop-opacity="0"></stop>
      	  </radialGradient><radialGradient id="rg0" fx="0.3006439454812611" fy="0.5">
      	    <stop offset="0%" stop-color="#5135FF"></stop>
      	    <stop offset="100%" stop-color="#5135FF" stop-opacity="0"></stop>
      	  </radialGradient><radialGradient id="rg0" fx="0.3012934258152014" fy="0.5">
      	    <stop offset="0%" stop-color="#5135FF"></stop>
      	    <stop offset="100%" stop-color="#5135FF" stop-opacity="0"></stop>
      	  </radialGradient><radialGradient id="rg1" fx="0.33950130231501097" fy="0.5">
      	    <stop offset="0%" stop-color="#FF5828"></stop>
      	    <stop offset="100%" stop-color="#FF5828" stop-opacity="0"></stop>
      	  </radialGradient><radialGradient id="rg1" fx="0.37372348135893574" fy="0.5">
      	    <stop offset="0%" stop-color="#FF5828"></stop>
      	    <stop offset="100%" stop-color="#FF5828" stop-opacity="0"></stop>
      	  </radialGradient><radialGradient id="rg1" fx="0.3738815592848491" fy="0.5">
      	    <stop offset="0%" stop-color="#FF5828"></stop>
      	    <stop offset="100%" stop-color="#FF5828" stop-opacity="0"></stop>
      	  </radialGradient><radialGradient id="rg2" fx="0.3622857763861167" fy="0.5">
      	    <stop offset="0%" stop-color="#F69CFF"></stop>
      	    <stop offset="100%" stop-color="#F69CFF" stop-opacity="0"></stop>
      	  </radialGradient><radialGradient id="rg2" fx="0.34466270581008895" fy="0.5">
      	    <stop offset="0%" stop-color="#F69CFF"></stop>
      	    <stop offset="100%" stop-color="#F69CFF" stop-opacity="0"></stop>
      	  </radialGradient><radialGradient id="rg2" fx="0.3028211106599178" fy="0.5">
      	    <stop offset="0%" stop-color="#F69CFF"></stop>
      	    <stop offset="100%" stop-color="#F69CFF" stop-opacity="0"></stop>
      	  </radialGradient><radialGradient id="rg3" fx="0.3955502809005557" fy="0.5">
      	    <stop offset="0%" stop-color="#FFA50F"></stop>
      	    <stop offset="100%" stop-color="#FFA50F" stop-opacity="0"></stop>
      	  </radialGradient><radialGradient id="rg3" fx="0.3328717071340566" fy="0.5">
      	    <stop offset="0%" stop-color="#FFA50F"></stop>
      	    <stop offset="100%" stop-color="#FFA50F" stop-opacity="0"></stop>
      	  </radialGradient><radialGradient id="rg3" fx="0.37692409028853774" fy="0.5">
      	    <stop offset="0%" stop-color="#FFA50F"></stop>
      	    <stop offset="100%" stop-color="#FFA50F" stop-opacity="0"></stop>
      	  </radialGradient>
      	</defs>
      <rect id="bg" x="0" y="0" width="100%" height="100%"></rect>
      <rect class="rect rect0" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(1.1180323883409724 1.0657506335510178) skewX(-27.048611455330047) rotate(357.02545653067654) translate(-669.871910088145 1256.29207896208) translate(-1500 -1500)"></rect>
      <rect class="rect rect2" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(0.8785811697754712 0.6704824287081103) skewX(-7.938661975434634) rotate(44.335238512618815) translate(-1010.1161383934932 919.7921238658411) translate(-1500 -1500)"></rect>
      <rect class="rect rect1" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(1.096725825815318 0.8345810514528191) skewX(-16.060948722528636) rotate(227.54072855195662) translate(1462.0260621077528 -156.33137200763247) translate(-1500 -1500)"></rect>
      <rect class="rect rect3" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(1.0849545599769375 0.9039921161252457) skewX(33.66447093964008) rotate(199.928430200172) translate(-312.2686772005634 873.0220186753318) translate(-1500 -1500)"></rect>
      <rect class="rect rect2" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(1.0198195921390885 0.9878039705781545) skewX(-37.60432645249379) rotate(317.28669194585933) translate(1484.0265339403 -605.1589776609087) translate(-1500 -1500)"></rect>
      <rect class="rect rect0" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(1.1872498628573886 1.2455049261396) skewX(22.85225406146138) rotate(63.17563936531593) translate(-656.5450587501883 329.83411209711835) translate(-1500 -1500)"></rect>
      <rect class="rect rect1" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(1.109528567802793 0.749305771107279) skewX(20.88429261906856) rotate(278.68041343057115) translate(-904.8756852193101 -960.7414267573967) translate(-1500 -1500)"></rect>
      <rect class="rect rect2" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(1.220336193842387 0.8373107654628316) skewX(-41.08421660357212) rotate(128.35879237273372) translate(348.5588616341647 302.21501347476544) translate(-1500 -1500)"></rect>
      <rect class="rect rect0" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(1.0656214056594173 0.7471082136107547) skewX(2.553712193144854) rotate(282.70026179573324) translate(633.4530163377688 -515.4580862175827) translate(-1500 -1500)"></rect>
      <rect class="rect rect3" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(1.1022541896740257 1.148321237765334) skewX(-16.370666281191088) rotate(192.25826153221266) translate(16.01633631807653 156.33751692518027) translate(-1500 -1500)"></rect>
      <rect class="rect rect1" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(0.9732827798916499 0.7796089841737821) skewX(22.821062173717863) rotate(77.25007939039506) translate(1241.3220463547602 -276.35392857037886) translate(-1500 -1500)"></rect>
      <rect class="rect rect3" x="0" y="0" width="100%" height="100%" transform="translate(1500 1500) scale(0.9522946118381022 1.088796929472856) skewX(6.875336281122863) rotate(25.643409792366167) translate(-1120.4220406015318 -714.4583848191629) translate(-1500 -1500)"></rect>
    </svg>
  </div>

  <div id="gradient-overlay" class="position-absolute w-100 h-100 trbl-0" style={{
    zIndex: '-1',
    pointerEvents: 'none',
    backgroundColor: 'rgba(0,0,0,1)',
    opacity: '0'
  }}></div>
</div>
	)
}

export default GradientThingy 