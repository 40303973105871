import React from 'react'
import sun_white from '../images/sun-white.svg'
import plural_word_magenta from '../images/plural-logo-v0-magenta.svg'

export const Nav = () => {
	return (
		<header style={{
		  display: 'flex',
		  justifyContent: 'space-between',
		  alignItems: 'center',
		  margin: 0,
		  padding: '1rem',
		  position: 'fixed',
		  left: 0,
		  right: 0,
		  zIndex: 10,
		}}>
		  <div className="left">
		  	<a href="/">  
					<img src={plural_word_magenta} alt="Plural" style={{
		  	    width: '6rem',
		  	  }}></img>
				</a>
		  </div>
			
		  <div className="right">
		  	<a href="/">  
					<img className="rotate" src={sun_white} alt="Plural logo" style={{
		  	    width: '4rem',
		  	  }}></img>
				</a>
			</div>
		</header>
	)
}

export default Nav 