import './App.css';
import Nav from './app/Nav'
import GradientThingy from './app/GradientThingy'

function App() {
  const colors = {
    ultramarinePurp: '#5135ff',
    cadmiumCrimson: '#FF5828',
    paleFuscia: '#F69CFF',
    cadmiumGold: '#FFA50F',
    
    lightOrange: '#FFF3F0',
  }

  return (
    <div className="App">
      <Nav />
      <header class="header-home position-relative overflow-hidden color-white" style={{position: 'relative'}}>
        <GradientThingy />
        
        <div class="d-flex flex-column justify-content-between h-100">
          <div class="header-home-container container">
            <div class="w-11/12" style={{maxWidth: '30rem', marginLeft: 'auto', marginRight: 'auto'}}>
              <h1 class="mb-0.75 font-sans-serif">
                A safe place for stimulating conversation
              </h1>
              <div class="medium-copy mb-1 content no-col">
                <br />
                <p>
                  Find your community, think out loud, and explore ideas.
                </p>
                <br />
              </div>
              <div class="btns" style={{marginTop: '1rem'}}>
                <a class="mono btn btn-padded btn-bg-bg btn-circle icon-external right" href="/">Request an invite</a>
                {/*<a class="mono btn btn-padded btn-bg-bg btn-circle" href="/">Learn more</a>*/}
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="container white" style={{padding: '2rem 1rem'}}>
        <div class="grid-3">
          <div class="panel">
            <h3>Every user has a pseudonym</h3>
            <p>
              To protect against harassment, all users post under pseudonym by default. 
            </p>
          </div>
          <div class="panel">
            <h3>All real identities are verified</h3>
            <p>
              All accounts are required to be verified. That way, bad actors can be banned permanently.
            </p>
          </div>
          <div class="panel">
            <h3>Share your identity with your friends</h3>
            <p>
              Decide which of your friends can see your real identity. If you and your friend both nominate to share with each other, you both get to see each other's real name.
            </p>
          </div>
        </div> 
      </div> 
    </div>
  );
}

export default App;
